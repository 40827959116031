.filledDot {
    height: 10px;
    width: 10px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .emptyDot {
    height: 10px;
    width: 10px;
    background-color: grey;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .livesDisplay {
    font-size: 18px;
    margin-bottom: 20px;
    /* Additional styling as needed */
  }  